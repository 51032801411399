import { NgModule } from '@angular/core';

import { MaterialDesignModule } from './modules';

import { ComponentsModule } from './components';

@NgModule({
    imports: [
        MaterialDesignModule,
        ComponentsModule
    ],
    exports: [
        MaterialDesignModule,
        ComponentsModule
    ]
})
export class SharedModule
{
}
