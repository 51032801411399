import { NgModule } from '@angular/core';

import {
    MdcAppBarModule,
    MdcButtonModule,
    MdcCheckboxModule,
    MdcDialogModule,
    MdcElevationModule,
    MdcFabModule,
    MdcFormFieldModule,
    MdcIconModule,
    MdcListModule,
    MdcMenuModule,
    MdcRadioModule,
    MdcRippleModule,
    MdcSelectModule,
    MdcTabModule,
    MdcSnackbarModule,
    MdcTextFieldModule,
    MdcThemeModule,
    MdcTypographyModule
} from '@angular-mdc/web';

@NgModule({
    imports: [
        MdcTypographyModule,
        MdcButtonModule,
        MdcRadioModule,
        MdcSelectModule,
        MdcFormFieldModule,
        MdcRippleModule,
        MdcTextFieldModule,
        MdcCheckboxModule,
        MdcAppBarModule,
        MdcDialogModule,
        MdcTabModule,
        MdcListModule,
        MdcMenuModule,
        MdcIconModule,
        MdcThemeModule,
        MdcFabModule,
        MdcSnackbarModule,
        MdcElevationModule
    ],
    exports: [
        MdcTypographyModule,
        MdcButtonModule,
        MdcIconModule,
        MdcFormFieldModule,
        MdcRippleModule,
        MdcCheckboxModule,
        MdcTextFieldModule,
        MdcRadioModule,
        MdcDialogModule,
        MdcAppBarModule,
        MdcTabModule,
        MdcSelectModule,
        MdcFabModule,
        MdcMenuModule,
        MdcListModule,
        MdcThemeModule,
        MdcSnackbarModule,
        MdcElevationModule
    ]
})
export class MaterialDesignModule
{
}
