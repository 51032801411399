import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoadingService
{
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router)
    {
        // clear alert message on route change
        router.events.subscribe(
            (event) =>
            {
                if (event instanceof NavigationStart)
                {
                    if (this.keepAfterNavigationChange)
                    {
                        // only keep for a single location change
                        this.keepAfterNavigationChange = false;
                    }
                    else
                    {
                        // clear alert
                        this.subject.next();
                    }
                }
            });
    }

    show(message = 'Loading...')
    {
        this.subject.next({ text: message });
    }

    hide()
    {
        this.subject.next();
    }

    getData(): Observable<any>
    {
        return this.subject.asObservable();
    }
}
