import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: './booking/booking.module#BookingModule' },
    { path: 'success', loadChildren: './success/success.module#SuccessModule' },
    { path: 'failure', loadChildren: './failure/failure.module#FailureModule' },
    { path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenanceModule' },
    { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule' },
    { path: 'wrong-page', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule' },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
