import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MdcSnackbar } from '@angular-mdc/web';

@Injectable()
export class AlertService
{
    constructor(private snackbar: MdcSnackbar)
    {
    }

    show(message = 'Some error, please try again', timeout = 4000)
    {
        this.snackbar.show(message, 'Got it', {
            align: 'start',
            multiline: true,
            dismissOnAction: true,
            actionOnBottom: true,
            timeout: timeout
        });
    }

    isShowing(): boolean
    {
        return this.snackbar.isShowing();
    }

    showErrorFromResponse(response: any)
    {
        if (response instanceof HttpErrorResponse)
        {
            if (response.error && response.error.hasOwnProperty('errors'))
            {
                const errors = response.error.errors;

                for (const key in errors)
                {
                    if (errors.hasOwnProperty(key))
                    {
                        this.show(errors[key]);

                        break;
                    }
                }
            }
        }
        else
        {
            this.show();
        }
    }
}
