import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Error } from 'tslint/lib/error';

import {
    RequestInterceptor,
    LocalStorageService,
    AlertService,
    GoogleTagManagerService
} from './services';

import { SharedModule } from '@app/shared';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        HttpClientModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        LocalStorageService,
        GoogleTagManagerService,
        AlertService
    ]
})
export class CoreModule
{
    /* make sure CoreModule is imported only by one NgModule the AppModule */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule)
    {
        if (parentModule)
        {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
