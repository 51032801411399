import { Injectable } from '@angular/core';

import * as moment from 'moment';

declare let ga: any;
declare let fbq: any;

import { CityModel, HotelModel, RoomModel, RateModel, TransactionModel, UserModel, BookingModel } from '@app/booking/models';

import { environment } from '@env/environment';

@Injectable()
export class GoogleTagManagerService
{
    private rate: RateModel;
    private analyticsData: {
        hotel: HotelModel,
        city: CityModel,
        checkIn: string,
        checkOut: string,
        adultsCount: number,
        childrenCount: number
    };

    private isAnalyticsEnabled(): boolean
    {
        return environment.isAnalyticsEnabled;
    }

    sendLoginAccountData(account: UserModel)
    {
        if (this.isAnalyticsEnabled())
        {
            window['dataLayer'].push({
                event: 'login',
                account: account
            });
        }
    }

    /**
     * Measures product impressions and also tracks a standard
     * pageview for the tag configuration.
     * Product impressions are sent by pushing an impressions object
     * containing one or more impressionFieldObjects.
     */
    sendLoadedRooms(rooms: RoomModel[],
                    analyticsData: {
                        hotel: HotelModel,
                        city: CityModel,
                        checkIn: string,
                        checkOut: string,
                        adultsCount: number,
                        childrenCount: number
                    })
    {
        if (!this.isAnalyticsEnabled())
        {
            return;
        }

        this.analyticsData = analyticsData;
        const impressions = [];

        rooms.forEach((room) =>
        {
            impressions.push({
                name: room.name,
                id: room.id,
                price: (Math.min(...room.rates.map((rate) => parseFloat(rate.price.formatted)))).toFixed(2),
                brand: analyticsData.hotel.name,
                list: 'Room List'
            });
        });

        window['dataLayer'].push({
            event: 'productImpressions',
            ecommerce: {
                currencyCode: 'ZAR',
                impressions: impressions
            },
            content_type: 'hotel',
            checkin_date: moment(analyticsData.checkIn).format('YYYY-MM-DD'),
            checkout_date: moment(analyticsData.checkOut).format('YYYY-MM-DD'),
            content_ids: analyticsData.hotel.id,
            city: analyticsData.city.name,
            region: analyticsData.city.name,
            country: 'South Africa',
            num_adults: analyticsData.adultsCount,
            num_children: analyticsData.childrenCount
        });
    }

    /**
     * Measure a view of product details. This example assumes the detail view occurs on pageload,
     * and also tracks a standard pageview of the details page.
     */
    roomDetailsChecked(room: RoomModel)
    {
        if (!this.isAnalyticsEnabled())
        {
            return;
        }

        window['dataLayer'].push({
            event: 'roomDetails',
            ecommerce: {
                detail: {
                    actionField: { list: 'Room List' }, // 'detail' actions have an optional list property.
                    products: [
                        {
                            name: room.name,
                            id: room.id,
                            price: (Math.min(...room.rates.map((rate) => parseFloat(rate.price.formatted)))).toFixed(2),
                            brand: this.analyticsData.hotel.name,
                        }
                    ]
                }
            },
            content_type: 'hotel',
            checkin_date: moment(this.analyticsData.checkIn).format('YYYY-MM-DD'),
            checkout_date: moment(this.analyticsData.checkOut).format('YYYY-MM-DD'),
            content_ids: this.analyticsData.hotel.id,
            city: this.analyticsData.city.name,
            region: this.analyticsData.city.name,
            country: 'South Africa',
            num_adults: this.analyticsData.adultsCount,
            num_children: this.analyticsData.childrenCount
        });
    }

    /**
     * Call this function when a user clicks on a product link.
     */
    roomSelected(room: RoomModel, rate: RateModel)
    {
        if (!this.isAnalyticsEnabled())
        {
            return;
        }

        this.rate = rate;

        window['dataLayer'].push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Room List' }, // Optional list property.
                    products: [
                        {
                            name: room.name,
                            id: room.id,
                            price: rate.price.formatted,
                            brand: this.analyticsData.hotel.name,
                            variant: rate.name
                        }
                    ]
                }
            },
            content_type: 'hotel',
            checkin_date: moment(this.analyticsData.checkIn).format('YYYY-MM-DD'),
            checkout_date: moment(this.analyticsData.checkOut).format('YYYY-MM-DD'),
            content_ids: this.analyticsData.hotel.id,
            city: this.analyticsData.city.name,
            region: this.analyticsData.city.name,
            country: 'South Africa',
            num_adults: this.analyticsData.adultsCount,
            num_children: this.analyticsData.childrenCount
        });

        fbq('track', 'AddToCart', { value: rate.price.formatted, currency: 'ZAR' });
    }

    /**
     * Measure adding a product to a shopping cart by using an 'add' actionFieldObject
     * and a list of productFieldObjects.
     *
     * facebook guide for additional fields after ecommerce object:
     * https://developers.facebook.com/docs/marketing-api/dynamic-ads-for-hotels/events/v3.1
     */
    payClicked(room: RoomModel, checkIn, checkOut, guestsCount: { adults: number, children: number })
    {
        if (!this.isAnalyticsEnabled())
        {
            return;
        }

        window['dataLayer'].push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'ZAR',
                add: { // 'add' actionFieldObject measures.
                    products: [ // adding a product to a shopping cart.
                        {
                            name: room.name,
                            id: room.id,
                            price: this.rate.price.formatted,
                            brand: this.analyticsData.hotel.name,
                            variant: this.rate.name,
                            quantity: 1
                        }
                    ]
                }
            },
            content_type: 'hotel',
            checkin_date: checkIn,
            checkout_date: checkOut,
            content_ids: this.analyticsData.hotel.id,
            city: this.analyticsData.city.name,
            region: this.analyticsData.city.name,
            country: 'South Africa',
            num_adults: guestsCount.adults,
            num_children: guestsCount.children
        });
    }

    /**
     *  Send transaction data with a pageview if available
     *  when the page loads. Otherwise, use an event when the transaction
     *  data becomes available.
     */
    paymentSuccessful(transaction: TransactionModel)
    {
        if (!this.isAnalyticsEnabled())
        {
            return;
        }

        const booking = transaction.booking;
        const price = transaction.total_cost / 100;

        window['dataLayer'].push({
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: transaction.protel_reference, // Transaction ID. Required for purchases and refunds.
                        affiliation: 'The Capital Web Booking',
                        revenue: price.toFixed(2), // Total transaction value (incl. tax and shipping)
                        tax: (price * 0.15).toFixed(2),
                        shipping: '0',
                        coupon: '',
                    },
                    products: [ // List of productFieldObjects.
                        {
                            name: booking.room_name, // Name or ID is required.
                            id: booking.room_id,
                            price: price.toFixed(2),
                            brand: booking.hotel.name,
                            variant: booking.rate_name,
                            quantity: 1,
                            coupon: '',
                        }
                    ]
                }
            },
            content_type: 'hotel',
            checkin_date: booking.check_in,
            checkout_date: booking.check_out,
            content_ids: booking.hotel.id,
            city: booking.hotel.city_id,
            region: booking.hotel.address,
            country: 'South Africa',
            num_adults: booking.adults_count,
            num_children: booking.children_count,
        });

        this.ecPaymentSuccessful(transaction, booking);
    }

    private ecPaymentSuccessful(transaction: TransactionModel, booking: BookingModel)
    {
        const price = transaction.total_cost / 100;

        fbq('track', 'Purchase', { value: price, currency: 'ZAR' });

        ga('ecommerce:addTransaction', {
            id: transaction.id,
            affiliation: 'The Capital Web Booking',
            revenue: price.toFixed(2), // Total transaction value (incl. tax and shipping)
            tax: (price * 0.15).toFixed(2),
            shipping: '0',
            currency: 'ZAR'  // local currency code.
        });

        ga('ecommerce:addItem', {
            id: transaction.id,
            name: booking.room_name,
            sku: booking.room_id,
            price: price.toFixed(2),
            quantity: booking.rooms_count,
            currency: 'ZAR' // local currency code.
        });

        ga('ecommerce:send');
    }
}
