export const environment = {
    production: true,
    maintenance: false,
    domain: 'https://thecapitalhotels.connecthotel.co.za/api/v4',
    // domain: 'http://capital-backend-v2/api/v4',
    debug: false,
    isAnalyticsEnabled: true,
    la_famiglia: true,

    exchangeApiKey: 'e4e2f7ce861b8fd0c584',
};
