import { Component, OnInit } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router,
    RouterEvent
} from '@angular/router';

import * as moment from 'moment';

import { AlertService } from '@app/core/services';

import { environment } from '@env/environment';

declare const ga: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
    constructor(private router: Router,
                private alertService: AlertService)
    {
    }

    ngOnInit()
    {
        this.router.events.subscribe((event: RouterEvent) =>
        {
            if (event instanceof NavigationEnd)
            {
                if (event.url.indexOf('/success') < 0)
                {
                    document.body.classList.add('loaded');
                }
                // ga('set', 'page', event.urlAfterRedirects);
                // ga('send', 'pageview');
            }

            // Set loading state to false in both of the below events to hide the spinner in case a request fails
            if (event instanceof NavigationCancel)
            {
                document.body.classList.add('loaded');
            }

            if (event instanceof NavigationError)
            {
                document.body.classList.add('loaded');
            }
        });

        if (environment.maintenance)
        {
            const eventStart = moment('21-05-2019 00:00:00+02:00', 'DD-MM-YYYY HH:mm:ssZZ');
            const eventEnd = moment('21-05-2019 04:02:00+02:00', 'DD-MM-YYYY HH:mm:ssZZ');
            const currentDate = moment(new Date());

            if (currentDate.isBetween(eventStart, eventEnd))
            {
                this.router.navigate(['/maintenance']);
            }
        }
    }
}
