import { Injectable } from '@angular/core';

import { CompanyModel, UserModel } from '@app/booking/models';

@Injectable()
export class LocalStorageService
{
    private static prefix = location.host;

    static get(key: string): any | null
    {
        const data = localStorage.getItem(key);

        if (!data || data === 'undefined')
        {
            return null;
        }

        return JSON.parse(data);
    }

    static set(key: string, data: any)
    {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static remove(key: string)
    {
        localStorage.removeItem(key);
    }

    static getToken(): string | null
    {
        return this.get(this.getValidKey('token'));
    }

    static getUser(): UserModel
    {
        return this.get(this.getValidKey('account'));
    }

    static login(data: { cloud_id: number, companies: CompanyModel[], token: string, account: UserModel })
    {
        this.set(this.getValidKey('companies'), data.companies);
        this.set(this.getValidKey('token'), data.token);
        this.set(this.getValidKey('account'), data.account);
        this.set(this.getValidKey('cloud_id'), data.cloud_id);
    }

    static getUserCloudId(): number
    {
        return this.get(this.getValidKey('cloud_id'));
    }

    static logout()
    {
        this.remove(this.getValidKey('cloud_id'));
        this.remove(this.getValidKey('token'));
        this.remove(this.getValidKey('companies'));
        this.remove(this.getValidKey('account'));
    }

    private static getValidKey(key: string): string
    {
        return this.prefix + '_' + key;
    }
}
