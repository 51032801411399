import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/core/services';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit
{
    data: { text: string } | undefined;

    constructor(private loadingService: LoadingService)
    {
    }

    ngOnInit()
    {
        this.loadingService.getData()
            .subscribe(
                (data: { text: string } | undefined) =>
                {
                    if (data)
                    {
                        document.body.classList.add('data-loading');
                    }
                    else
                    {
                        document.body.classList.remove('data-loading');
                    }

                    this.data = data;
                });
    }
}
