import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

import { LocalStorageService } from './local-storage.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Get the auth header from the service.
        const token = LocalStorageService.getToken();
        let url = req.url;

        if (url.indexOf('https://api.exchangeratesapi.io') > -1)
        {
            return this.sendRequest(req, next);
        }
        else if (url.indexOf('https://pay.ozow.com') > -1)
        {
            return this.sendRequest(req, next);
        }
        else if (url.indexOf('https://www.mews.li') > -1)
        {
            return this.sendMewsRequest(req, next);
        }
        else
        {
            url = url.indexOf(environment.domain) > -1 ?
                url :
                environment.domain + url;

            if (environment.debug)
            {
                url += '?XDEBUG_SESSION_START=PHPSTORM';
            }
        }

        if (!token)
        {
            const apiReq = req.clone({
                url: url
            });

            return next.handle(apiReq);
        }
        else
        {
            // Get the auth header from the service.
            const authHeader = 'Bearer ' + token;
            // Clone the request to add the new header.
            const authReq = req.clone({
                setHeaders: {
                    Authorization: authHeader,
                },
                url: url
            });
            // Pass on the cloned request instead of the original request.
            return next.handle(authReq);
        }
    }

    private sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const apiReq = req.clone({
            url: req.url
        });

        return next.handle(apiReq);
    }

    private sendMewsRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const apiReq = req.clone({
            url: req.url
        });

        return next.handle(apiReq);
    }
}
